import { useState ,useEffect } from "react";
import useTimeCalc from "./useTimeCalc.js";

const FormApp = ()=>{
  /* --------------------------------------------------------------------- 
  合計時間捻出
  -----------------------------------------------------------------------*/
  const {
    setSectionCount,
    setHtmlTime,
    setCssTime,
    setHtmlSection,
    setCssSection,
    countSection,
    countHtmlTime,
    countHtmlSection,
    countCssTime,
    countCssSection,
    sectionCount,
    htmlTIme,
    htmlSection,
    cssTime,
    cssSection,
    htmlCssTotalMin,
    totalMin,
    totalHour,
    totalCss,
    totalHtml,
    day,
    setDay,
    countDay,
    workFlowDayHour,
    workFlowDayMin,
    workFlowDayMinHtml,
    workFlowDayMinCSS
  } = useTimeCalc();

  /* --------------------------------------------------------------------- 
  新規タスク登録
  -----------------------------------------------------------------------*/
  const newTask =()=>{
    setSectionCount(0);
    setHtmlTime(0);
    setCssTime(0);
    setDay(0);
    setProjectName('');
  }

  /* --------------------------------------------------------------------- 
  入力した情報を登録
  -----------------------------------------------------------------------*/
  const [taskList, setTaskList] = useState([]);
  const [projectName, setProjectName] = useState();

  const editProjectName = (e)=>{
    setProjectName(e.target.value);
  }

  useEffect(() => {
    updateTasks();
  }, []);

  //ローカルストレージからキーと値を取得し、オブジェクトとしてtaskItemsに追加
  const updateTasks = () => {
    let taskItems = [];

    for (let i = 0; i < localStorage.length; i++) {
      const taskName = localStorage.key(i);
      const taskValue = localStorage.getItem(taskName);
      taskItems.push({ taskName, taskValue });
    }

    setTaskList(taskItems);
  };

  const saveWork = () => {
    const projectData = {
      projectName,
      htmlTIme,
      cssTime,
      sectionCount,
      totalMin,
      totalHour,
      day,
      cssSection,
      htmlSection,
      workFlowDayMin,
      workFlowDayHour,
      workFlowDayMinHtml,
      workFlowDayMinCSS,
    };

    // json形式でローカルストレージに保存
    localStorage.setItem(projectName, JSON.stringify(projectData));

    updateTasks();
  };

  const [clickResult, setClickResult] = useState(null);

  //クリックされたタスク名からタスク内容を出力
  const taskEdit = (e)=>{
    //クリックされたタスクの中身を表示
    const taskText =  e.currentTarget.dataset.task;

    const result = taskList.find((item) => item.taskName === taskText);
    if (result) {
      setClickResult(result);
    }
  }

  useEffect(() => {
    if (clickResult) {
      const projectData = JSON.parse(clickResult.taskValue);
      setProjectName(projectData.projectName);
      setHtmlTime(projectData.htmlTIme);
      setCssTime(projectData.cssTime);
      setCssSection(projectData.cssSection);
      setHtmlSection(projectData.htmlSection);
      setSectionCount(projectData.sectionCount);
      setDay(projectData.day);
    }
  }, [clickResult]);

  const taskRemove = (e)=>{
    const removeTargetName = e.target.dataset.remove;
    localStorage.removeItem(removeTargetName);
    setTaskList(taskList.filter((item) => item.taskName !== removeTargetName));
  }

  const taskItems = taskList.map((item, index) => (
    <li className="aside__item" onClick={taskEdit} key={index} data-task={item.taskName}>
      <span className="aside__item_txt">{item.taskName}</span>
      <button className="aside__item_remove" onClick={taskRemove} data-remove={item.taskName}>削除</button>
    </li>
  ));

  return(
    <>
      <div className="wrapper">
        <aside className="aside">
          <nav className="aside__nav">
            <ul className="aside__list">
              {taskItems}
            </ul>
            <button className="aside__new-btn" onClick={newTask}>新規登録</button>
          </nav>
        </aside>
        <div className="form">
          <div className="form__inner">

            <div className="form__item">
              <p className="form__item_ttl">この案件の名称</p>
              <input className="form__item_input" type="text" onChange={editProjectName} value={projectName}/>
            </div>

            <div className="form__item">
              <p className="form__item_ttl">あなたがHTMLコーディングにかかる時間（分）</p>
              <input className="form__item_input" type="number" onChange={ countHtmlTime } value={htmlTIme}/>
            </div>

            <div className="form__item">
              <p className="form__item_ttl">あなたがCSSコーディングにかかる時間（分）</p>
              <input className="form__item_input" type="number" onChange={ countCssTime } value={cssTime}/>
            </div>

            <div className="form__item">
              <p className="form__item_ttl">セクション数</p>
              <input className="form__item_input" type="number" onChange={ countSection } value={sectionCount}/>
            </div>

            <div className="form__item">
              <p className="form__item_ttl">各種計算結果</p>
              <p className="form__item_answer">合計(分) : { totalMin } 分</p>
              <p className="form__item_answer">合計(時間) : { totalHour } 時間</p>
              <p className="form__item_answer">CSS : { totalCss } 分</p>
              <p className="form__item_answer">HTML : { totalHtml } 時間</p>
            </div>

            <div className="form__item">
              <p className="form__item_ttl">HTML残りのセクション数</p>
              <input className="form__item_input" type="number" onChange={countHtmlSection} value={htmlSection}/>
            </div>

            <div className="form__item">
              <p className="form__item_ttl">CSS残りのセクション数</p>
              <input className="form__item_input" type="number" onChange={countCssSection} value={cssSection}/>
            </div>

            <div className="form__item">
              <p className="form__item_ttl">掛けたい日数</p>
              <input className="form__item_input" type="number" onChange={ countDay } value={day}/>
              <p className="form__item_answer">1日あたり(分) : { workFlowDayMin }分</p>
              <p className="form__item_answer">1日あたり(時間) : { workFlowDayHour }時間</p>
              <p className="form__item_answer">HTMLだと1日あたり: { workFlowDayMinHtml }セクション進められそうだね</p>
              <p className="form__item_answer">CSSだと1日あたり : { workFlowDayMinCSS }セクション進められそうだね</p>
            </div>

            <button onClick={saveWork} className="form__submit">登録</button>
          </div>
        </div>

      </div>
    </>
  )
}

export {FormApp};