import { useState } from "react";

const useTimeCalc = ()=>{
  /* --------------------------------------------------------------------- 
  合計時間捻出
  -----------------------------------------------------------------------*/
  const [sectionCount ,setSectionCount] = useState();
  const [htmlTIme , setHtmlTime] = useState();
  const [cssTime , setCssTime] = useState();
  const [cssSection ,setCssSection] = useState();
  const [htmlSection ,setHtmlSection] = useState();

  const countSection = (e)=> {
    setSectionCount(Number(e.target.value));
    setCssSection(Number(e.target.value));
    setHtmlSection(Number(e.target.value));
  }

  const countCssSection = (e)=>{
    if(e.target.value == htmlSection){
      setSectionCount(Number(e.target.value));
    }
    setCssSection(Number(e.target.value));
  }

  const countHtmlSection = (e)=>{
    if(cssSection == e.target.value){
      setSectionCount(Number(e.target.value));
    }
    setHtmlSection(Number(e.target.value));
  }

  const countHtmlTime = (e)=> {
    setHtmlTime(Number(e.target.value));
  }

  const countCssTime = (e)=> {
    setCssTime(Number(e.target.value));
  }

  //それぞれの作業にかかる時間の合計
  let htmlCssTotalMin = htmlTIme + cssTime;

  //基準の変数
  const totalMin = sectionCount * htmlCssTotalMin;

  const totalHour = totalMin / 60;
  const totalCss = cssTime * cssSection;
  const totalHtml = htmlTIme * htmlSection;

  /* --------------------------------------------------------------------- 
  1日あたりの時間や、対応出来るセクション数を計算
  -----------------------------------------------------------------------*/
  const [day,setDay] = useState();

  const countDay = (e)=> {
    setDay(Number(e.target.value));
  }

  const workFlowDayMin = totalMin / day;
  const workFlowDayHour = workFlowDayMin / 60;
  const workFlowDayMinHtml = workFlowDayMin / htmlTIme;
  const workFlowDayMinCSS = workFlowDayMin / cssTime;

  return{
    setSectionCount,
    setHtmlTime,
    setCssTime,
    setHtmlSection,
    setCssSection,
    countSection,
    countHtmlTime,
    countHtmlSection,
    countCssTime,
    countCssSection,
    sectionCount,
    htmlTIme,
    htmlSection,
    cssTime,
    cssSection,
    htmlCssTotalMin,
    totalMin,
    totalHour,
    totalCss,
    totalHtml,
    day,
    setDay,
    countDay,
    workFlowDayHour,
    workFlowDayMin,
    workFlowDayMinHtml,
    workFlowDayMinCSS
  }
}

export default useTimeCalc;