import { FormApp } from './components/FormApp.js';
import "./css/style.css";

function App() {
  return (
    <>
      <FormApp />
    </>
  );
}

export default App;
